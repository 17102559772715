@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Vars

$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1199px;
$screen-lg-min: 1200px;

$white: #ffffff;
$green: #82bc00;
$green_hover: #679500;
$gray: #ededed;
$text_black: #767676;

$header_height: 100px;

@mixin transition($propery, $time, $delay){
  -webkit-transition: $propery $time $delay;
  transition: $propery $time $delay;
}

@mixin shadow($horizontal, $vertical, $blur, $spread, $color){
  -webkit-box-shadow: $horizontal $vertical $blur $spread $color;
  -moz-box-shadow: $horizontal $vertical $blur $spread $color;
  box-shadow: $horizontal $vertical $blur $spread $color;
}

@mixin border-radius($top-left, $top-right, $bottom-right, $bottom-left){
  -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
  -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
  border-radius: $top-left $top-right $bottom-right $bottom-left;
}

@font-face {
  font-family: 'Frutinger LT Light';
  src: url('../../fonts/frutiger-lt-light.ttf')  format('truetype');
}

.btn{
  @include transition(all, 200ms, ease-in-out);
}

.btn-lg{
  padding: 10px 50px;
}

.btn-green{
  background-color: $green;
  color: $white;
  &:hover,&:focus,&:active{
    background-color: $green_hover;
    color: $white;
  }
}

.green{
  color: $green;
}

body{
  background-color: #dbdbdb;
  color: $text_black;
  font-family: 'Frutinger LT Light';
  font-size: 15px;
  &.page_login{
    background-color: $green;
  }
}

h1, h2, h3, h4, h5{
  font-family: 'Raleway', sans-serif;
}

.header{
  background: $white;
  height: $header_height;
  padding: 15px 0;
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  z-index: 99;
  a.logo_eldora{
    display: inline-block;
  }
  img{
    max-height: 70px;
    max-width: 100%;
  }
}

@media (max-width: $screen-xs-max ){
  .header{
    height: 60px;
    padding: 10px 0;
    a.logo_eldora{
      float: left;
      width: 40%;
    }
    .secondary_brand{
      width: 40%;
    }
  }

}

.push-content{
  height: $header_height;
}

.form-control{
  border-radius: 0;
  box-shadow: none;
}

.login{
  background-color: $white;
  @include shadow(0px, 0px, 44px, 0px, rgba(0,0,0,.3));
  @include border-radius(10px, 10px, 10px, 10px);
  padding: 50px 30px;
  width: 100%;
  max-width: 500px;
  margin: 200px auto 0;
  .btn{
    margin-top: 100px;
  }
  .form-control{
    font-size: 1.5em;
    border: 0;
    padding: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid $green;
  }
}

@media (max-width: $screen-xs-max){
  .login{
    margin-top: 20px;
    .btn{
      margin-top: 50px;
    }
  }
}

.form-group{
  position: relative;
}

.form-control:focus{
  outline: 0;
  box-shadow: none;
}

.form-helper{
  position: absolute;
  right: 0;
  bottom: 15px;
  a{
    color: $green;
  }
}

.sidebar{
  background-color: $green;
  height: 100%;
  width: 16%;
  min-width: 220px;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 40px;
  z-index: 98;
  @include shadow(20px, 10px, 44px, 0px, rgba(126,109,109,.25));
  ul{
    margin-top: $header_height;
    li{
      position: relative;
      border-bottom: 1px solid #bbd87a;
      &:first-child{
        border-top: 1px solid #bbd87a;
      }
      a{
        display: block;
        padding: 15px 20px 15px 70px;
        color: $white;
        font-size: 20px;
        text-align: right;
        @include transition(background-color, 200ms, ease-in-out);
        &:hover{
          text-decoration: none;
          background-color: $green_hover;
        }
        span.item-icon{
          width: 40px;
          border-right: 1px solid #bbd87a;
          position: absolute;
          top: 0; left: 15px;
          height: 100%;
          padding-right: 15px;
          padding-top: 15px;
          text-align: center;
        }
      }
    }
    &.logout{
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin-bottom: 0;
      li:last-child{
        border-bottom: 0;
      }
      .fa.fa-sign-out{
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
      }
    }
  }
}

.sidebar-toggle{
  position: absolute;
  z-index: 101;
  top: 75px;
  right: -35px;
  a{
    background-color: $white;
    color: $green;
    font-weight: 600;
    font-size: 1.2em;
    display: block;
    padding: 5px 10px;
    &:hover, &:active, &:focus{
      text-decoration: none;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .sidebar{
    width: 100%;
    right: 100%;
    left: auto;
    @include transition(all, 100ms, ease-in-out);
    @include shadow(0, 0, 0, 0, rgba(126,109,109,.0));
    &.shown{
      right: 0;
      .sidebar-toggle{
        right: 15px;
      }
    }
  }
}

.content{
  background-color: $white;
  padding: 30px;
  margin: 40px;
}

.page_header{
  margin-bottom: 30px;
  ul{
    li{
      display: inline-block;
      background-color: #f6f6f6;
      padding: 10px 30px 10px 10px;
      span.item-icon{
        float: right;
        margin-left: 40px;
      }
    }
  }
}

.header_green{
  background-color: $green;
  color: $white;
  padding: 15px 15px 15px 30px;
  margin: 40px 40px 0;
  position: relative;
}

@media (max-width: $screen-xs-max){
  .content{
    padding: 20px;
    margin: 40px 5px;
  }

  .header_green{
    margin: 40px 0 0;
  }
}

.activity_date{

  .day{
    font-size: 6em;
  }

  .month{
    font-size: 2em;
    font-weight: 600;
  }
}

.activity_hour{
  font-weight: 600;
  .year{
    font-size: 1.6em;
  }
}

.next_date{
  color: $white;
  display: inline-block;
  margin-top: 40px;
  .fa{
    font-size: 2em;
    position: relative;
    top: 4px;
  }
  &:hover, &:focus, &:active{
    color: $white;
    text-decoration: none;
  }
}

@media(max-width: $screen-xs-max){
  .activity_hour{
    text-align: left;
  }
}

.details_item{
  padding: 15px;
  position: relative;
  border-bottom: 1px solid #cccccc;

  .title{
    text-transform: uppercase;
    color: $green;
  }

  .hour{
    font-weight: 600;
  }

  .actions{
    position: absolute;
    right: 15px;
    bottom: 0px;
    li{
      display: inline-block;
      a{
        display: block;
        color: #333;
        font-size: 1.3em;
        &.icon-trash{
          font-size: 1.5em;
        }
      }
    }
  }

  &:last-child{
    border-bottom: 0;
  }

  &:nth-child(odd){
    background-color: #f5f5f5;
  }

}

.page_footer{
  overflow: hidden;
}

.pagination{
  margin-bottom: 0;
  li{
    margin-left: 5px;
    display: inline-block;
    a{
      background-color: #f6f6f6;
      border: 0;
      @include transition(background-color, 200ms, ease-in-out);
      color: $text_black;
      &.explicit{
        background: transparent;
      }
      &:hover{
        color: $text_black;
      }
    }
  }
}

.posts{
  background-color: transparent;
}

@media (max-width: $screen-xs-max){
  .posts{
    padding: 0;
    margin: 40px 0;
  }
}

.post{
  background-color: $white;
  margin-bottom: 50px;
  overflow: hidden;
  position: relative;
  .post-left{
    background-color: $green;
    color: $white;
    text-align: center;
    max-width: 200px;
    width: 30%;
    float: left;
    position: relative;
    z-index: 3;
    .month{
      padding: 5px;
      text-transform: uppercase;
      font-size: 1.3em;
      background-color: $green_hover;
    }
    .date{
      font-size: 4.7em;
      line-height: 1em;
      margin-top: 10px;
    }
    .year{
      margin-bottom: 10px;
    }
    .readmore{
      display: inline-block;
      background-color: $green_hover;
      color: $white;
      padding: 5px 20px;
      margin-bottom: 15px;
      &:hover{
        color: $white;
        text-decoration: none;
      }
    }
  }
  .post-right{
    float: left;
    height: 12.9em;
    min-width: calc(100% - 200px);
    width: 70%;
    position: relative;
    overflow-y: scroll;
    table{
      margin-bottom: 0;
      tbody > tr:first-child > td{
        border-top: 0;
      }
    }
  }
}

.nicescrollable{
  &:after{
    content: '';
    display: inline-block;
    width: 17px;
    background-color: #eaeaea;
    height: calc(100% - 34px);
    position: absolute;
    top: 17px; right: 0;
    z-index: 2;
  }
  @media(max-width: $screen-sm-max){
    &:after{
      display: none;
    }
  }
}

.scroll-fix{
  position: absolute;
  top: 0; right: 15px; bottom: 0; left: 0;
  background: transparent;
  z-index: 2;
}

@media(max-width: $screen-xs-max){
  .post{
    .post-left{
      width: 100%;
      max-width: 100%;
    }
    .post-right{
      width: 100%;
      overflow-y: auto;
      height: auto;
    }
  }
}

.divider{
  border-bottom: 2px solid #d8d8d8;
  margin: 60px 0 80px;
  width: 60%;
}

.datepicker{
  z-index: 199!important;
}

.datepicker .table-condensed{
  width: 100%;
}

.datepicker td, .datepicker th{
  @include border-radius(0,0,0,0);
  border: 1px solid #d0d4d9;
  font-family: "Arial", sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #4d5155;
}

.datepicker .datepicker-switch, .datepicker .next, .datepicker .prev, .datepicker tfoot tr th{
  background-color: #e1e6eb;
}

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active.disabled:hover[disabled], .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active:active, .datepicker table tr td.active:hover, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover.disabled, .datepicker table tr td.active:hover:active, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active:hover[disabled], .datepicker table tr td.active[disabled],.datepicker table tr td.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover{
  background-color: #eef1f5;
  color: #ef733c;
  border-color: #ef733c;
  background-image: none;
}

.create-post{
  .date-inputs{
    margin-top: 50px;
  }
  .form-horizontal{
    label{
      margin-top: 5px;
    }
  }
}

.buttons-container{
  .btn{
    @include border-radius(0, 0, 0, 0);
    text-transform: uppercase;
  }
}
